import React from "react";
import styles from "../styles/components/home.module.css";

function Home() {
  return (
    <div className={styles.Home}>
      <div className={styles.Title}>
        <h1>DerCode.pl</h1>
        <p>
          Najlepszy sklep z botami, stronami oraz pluginami do serwerów gry
          Minecraft
        </p>
        <button onClick={() => window.location.replace("/discord")}>
          Zobacz naszą ofertę
        </button>
      </div>
      <div className={styles.Bubble}></div>
    </div>
  );
}

export default Home;
